import React, { useEffect } from 'react'
import { Outlet, useMatches } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProviderComponent } from '../ThemeContext'
import { store } from '../store'
import { PageType } from './PageType'
import { Layout } from '../components/Layout/Layout'
import { withAuthGuard } from './withAuthGuard'

const LayoutWithAuthGuard = withAuthGuard(Layout)

export function AppRoot () {
  const matches = useMatches()
  const pageType = matches[matches.length - 1]?.id || PageType.Dashboard

  useEffect(() => {
    localStorage.removeItem('dynamic-import-error')
  }, [])

  return (
    <Provider store={store}>
      <ThemeProviderComponent>
        <LayoutWithAuthGuard pageType={pageType}>
          <Outlet />
        </LayoutWithAuthGuard>
      </ThemeProviderComponent>
    </Provider>
  )
}

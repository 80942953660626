import React from 'react'
import { createRoot } from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import { appRouter } from './appRouter'

export function initReact () {
  const domNode = document.getElementById('clientpanelApp')
  if (!domNode) return

  createRoot(domNode).render((
    <RouterProvider router={appRouter} />
  ))
}

import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import {
  AppRoot,
  AccountsRoot,
  withAuth,
  ErrorPage,
  PageType
} from './pages'

const accountRoutes = [
  {
    path: '/',
    element: <AccountsRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        lazy: async () => {
          const { LoginForm } = await import('./components/account/LoginForm')
          return { Component: LoginForm }
        }
      },
      {
        path: 'reset-password',
        lazy: async () => {
          const { ResetPasswordForm } = await import('./components/account/ResetPasswordForm')
          return { Component: ResetPasswordForm }
        }
      },
      {
        path: 'forgot-password',
        lazy: async () => {
          const { PasswordForgottenForm } = await import('./components/account/PasswordForgottenForm')
          return { Component: PasswordForgottenForm }
        }
      }
    ]
  },
]

const appRoutes = [
  {
    path: '/cpanel',
    element: <AppRoot />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        id: PageType.Dashboard,
        lazy: async () => {
          const { DashboardPage } = await import('./pages/DashboardPage')
          return { Component: DashboardPage }
        }
      },
      {
        path: 'reports/ads',
        id: PageType.Ads,
        lazy: async () => {
          const { AdsPage } = await import('./pages/AdsPage')
          return { Component: AdsPage }
        }
      },
      {
        path: 'reports/ads/:adsId/campaign/:campaignId',
        id: PageType.Campaign,
        lazy: async () => {
          const { CampaignPage } = await import('./pages/CampaignPage')
          return { Component: CampaignPage }
        }
      },
      {
        path: 'reports/seo',
        id: PageType.Seo,
        lazy: async () => {
          const { SeoPage } = await import('./pages/SeoPage')
          return { Component: SeoPage }
        }
      },
      {
        path: 'reports/facebook',
        id: PageType.Facebook,
        lazy: async () => {
          const { FacebookPage } = await import('./pages/FacebookPage')
          return { Component: FacebookPage }
        }
      },
      {
        path: 'billing',
        id: PageType.Billing,
        lazy: async () => {
          const { BillingPage } = await import('./pages/BillingPage')
          return { Component: BillingPage }
        }
      },
      {
        path: 'settings',
        id: PageType.Settings,
        lazy: async () => {
          const { SettingsPage } = await import('./pages/SettingsPage')
          return { Component: withAuth(SettingsPage) }
        }
      }
    ]
  }
]

const onboardingRoutes = [
  {
    path: '/onboarding',
    lazy: async () => {
      const { OnboardingPage } = await import('./pages/OnboardingPage')
      return { Component: OnboardingPage }
    }
  }
]

export const appRouter = createBrowserRouter([
  ...accountRoutes,
  ...appRoutes,
  ...onboardingRoutes
])

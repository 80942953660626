import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { Provider } from 'react-redux'
import { ThemeProviderComponent } from '../ThemeContext'
import { storePub } from '../store'
import { AccountLayout } from '../components/Layout/AccountLayout'

export function AccountsRoot () {
  useEffect(() => {
    localStorage.removeItem('dynamic-import-error')
  }, [])

  return (
    <Provider store={storePub}>
      <ThemeProviderComponent>
        <AccountLayout>
          <Outlet />
        </AccountLayout>
      </ThemeProviderComponent>
    </Provider>
  )
}

import React from 'react'
import { useRouteError } from 'react-router-dom'

export function ErrorPage () {
  const error = useRouteError()

  // If the error is due to a dynamic import failure, reload the page
  if ((error?.message || '').includes('Failed to fetch dynamically imported module')
    && !localStorage.getItem('dynamic-import-error')
  ) {
    localStorage.setItem('dynamic-import-error', '1')
    window.location.reload()
    return
  }

  console.error(error)

  return (
    <>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message || 'Unknown error'}</i>
      </p>
    </>
  )
}
